<template>
  <div>
    <div class="container pt-3 pb-1">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" v-if="course">
          <li class="breadcrumb-item">
            <router-link to="#"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/admin/courses">Kursus</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ course.attributes.course_name }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="header-full">
      <div class="container">
        <div class="container-header">
          <div class="row align-items-center px-3">
            <div class="col-md-6">
              <h2 v-if="course">{{ course.attributes.course_name }}</h2>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-between">
                <button
                  class="btn btn-outline-secondary w-50 me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addTopicModal"
                >
                  Tambah Topik +
                </button>
                <input
                  type="text"
                  class="form-control w-100"
                  v-model="searchValue"
                  @change="searchTopic"
                  placeholder="Carian topik..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-full">
      <div class="container pt-3 pb-4">
        <div class="row mx-0">
          <div class="col-md-8">
            <div class="container-box">
              <h5>Senarai Topik</h5>
              <table
                class="table table-hover table-borderless mt-3"
                width="100%"
              >
                <thead>
                  <tr>
                    <th width="4%">#</th>
                    <th width="32%">Nama Topik</th>
                    <!-- <th width="42%">Subtopik</th> -->
                    <th width="42%">Kandungan</th>
                    <th width="12%">Kuiz</th>
                    <th width="10%">Tindakan</th>
                  </tr>
                </thead>
                <tbody
                  v-if="course && filteredTopics && filteredTopics.length > 0"
                >
                  <tr v-for="(topic, ind) in filteredTopics" :key="topic.id">
                    <td scope="row">{{ ind + 1 }}</td>
                    <td>
                      <router-link :to="`/admin/topics/${topic.id}`">{{
                        topic.attributes.topic_name
                      }}</router-link>
                    </td>
                    <td>
                      <div style="width: 100%; overflow: auto">
                        <span
                          v-for="(subtopic, i) in topic.attributes.subtopics.data"
                          :key="'subtopic' + i + subtopic.id"
                          class="badge bg-secondary me-1"
                        >
                          {{ subtopic.attributes.subtopic_name }}
                        </span>
                      </div>
                    </td>
                    <!-- <td>
                      <span
                        v-for="(category, i) in topic.attributes.categories
                          .data"
                        :key="category.id + i"
                        class="badge bg-secondary"
                      >
                        {{ category.attributes.category_name }}
                      </span>
                    </td> -->
                    <td>
                      <span
                        v-if="!topic.attributes.has_quiz"
                        class="badge bg-danger"
                        >TIADA</span
                      >
                      <span v-else class="badge bg-success">YA</span>
                    </td>
                    <td>
                      <router-link
                        class="float-start"
                        :to="`/admin/topics/${topic.id}`"
                        ><i class="fas fa-pen"></i
                      ></router-link>
                      <span class="float-end" @click="deleteTopic(topic)">
                        <i class="fas fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row mx-0">
              <div class="col-12 mt-3">
                <div class="container-box">
                  <h5>Tetapan</h5>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Parameter bank soalan</label
                    >
                    <input
                      type="number"
                      v-model="courseForm.question_limit"
                      class="form-control"
                      placeholder="25"
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Masa menjawab (minit)</label
                    >
                    <input
                      type="text"
                      v-model="courseForm.answer_time_limit"
                      class="form-control"
                      min="1"
                      step="1"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="60"
                    />
                  </div>

                  <div class="form-group mt-3 custom-checkbox">
                    <label for="name" class="control-label mb-1"
                      >Status kursus</label
                    ><br />
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="courseForm.is_active"
                      id="kuiz"
                    />
                    <label class="form-check-label ms-3" for="kuiz">
                      {{ courseForm.is_active ? "Aktif" : "Tidak aktif" }}
                    </label>
                  </div>

                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Soalan dari tag</label
                    >
                    <input
                      type="text"
                      value=""
                      class="form-control"
                      placeholder="KPH"
                    />
                  </div>

                  <div class="form-group mt-3">
                    <label for="name" class="control-label mb-1"
                      >Akses kepada</label
                    >
                    <div
                      v-for="access_type in user_access_types"
                      :key="access_type.id"
                      class="form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="courseForm.user_access_types"
                        :value="access_type.id"
                      />
                      <label class="form-check-label">
                        {{ access_type.attributes.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="showSubmitUpdateBtn()">
                <div class="d-grid gap-2 mt-3">
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="submitUpdateCourse"
                  >
                    Kemaskini Maklumat Kursus
                  </button>
                </div>
              </div>
              <div class="d-grid gap-2 mt-3">
                <button
                  class="btn btn-danger"
                  type="button"
                  @click="deleteCourse()"
                >
                  Padam Kursus
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="addTopicModal"
      tabindex="-1"
      aria-labelledby="addTopicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-12">
                <h5>Tambah Topik Baru</h5>
                <p>
                  Sila tambah kursus mengikut kriteria-kriteria seperti yang
                  tertera.
                </p>

                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Nama Topik</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan nama topik"
                    v-model="topicForm.topic_name"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Tentang Topik</label
                  >
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Penerangan topik"
                    v-model="topicForm.description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-primary btn-full"
              @click="submitAddTopic"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      course: null,
      filteredTopics: null,
      fileList: null,
      searchValue: "",
      topicForm: {
        topic_name: null,
        description: null,
        course: this.$route.params.courseid,
        has_quiz: null
      },
      courseForm: {
        question_limit: null,
        answer_time_limit: null,
        is_active: null,
        user_access_types: [],
        user_access_types_default: []
      },
      user_access_types: []
    };
  },
  methods: {
    async getCourse() {
      const res = await API.get(
        `courses/${this.$route.params.courseid}?populate[topics][populate]=*&populate=user_access_types`
      );
      this.course = res.data.data;
      this.course.attributes.question_limit = this.course.attributes
        .question_limit ?
        this.course.attributes.question_limit.toString() :
        null;
      this.filteredTopics = res.data.data.attributes.topics.data ?
        [...res.data.data.attributes.topics.data] :
        null;
      this.courseForm.question_limit = this.course.attributes.question_limit
      this.courseForm.answer_time_limit = this.course.attributes.answer_time_limit
      this.courseForm.is_active = this.course.attributes.is_active
      this.courseForm.user_access_types = this.course.attributes.user_access_types.data.length > 0 ? this.course.attributes.user_access_types.data.map(item => {
        return item.id
      }) : []
      this.courseForm.user_access_types_default = this.courseForm.user_access_types
    },
    goto(_path) {
      this.$router.push(_path);
    },
    searchTopic() {
      if (this.searchValue !== "") {
        this.filteredTopics = this.course.attributes.topics.data.filter((o) => {
          return o.attributes.topic_name
            .toUpperCase()
            .indexOf(this.searchValue.toUpperCase()) == -1
            ? false
            : true;
        });
      } else {
        this.filteredTopics = this.course.attributes.topics.data;
      }
    },
    async deleteTopic(topic) {
      const res = await API.delete(`topics/${topic.id}`)

      if (res.toDelete) {
          let index = this.filteredTopics.findIndex(item => item.id == topic.id)
          this.filteredTopics.splice(index, 1)
      }
    },
    async submitAddTopic() {
      const res = await API.post("topics?populate=*", { data: this.topicForm })
      this.searchValue = ""
      this.course.attributes.topics.data.push(res.data.data)
      this.filteredTopics = [...this.course.attributes.topics.data]
      this.topicForm.topic_name = null
      this.topicForm.description = null
      $("#addTopicModal").toggle()
      $(".modal-backdrop").remove()
    },
    async submitUpdateCourse() {
      const res = await API.put(`courses/${this.$route.params.courseid}`, {
        data: this.courseForm,
      })
      await this.getCourse()
    },
    showSubmitUpdateBtn() {
      return this.course &&
        (this.courseForm.question_limit != this.course.attributes.question_limit ||
          this.courseForm.answer_time_limit != this.course.attributes.answer_time_limit ||
          this.courseForm.is_active != this.course.attributes.is_active) ||
          this.courseForm.user_access_types_default != this.courseForm.user_access_types
    },
    async deleteCourse() {
      const res = await API.delete("courses/" + this.$route.params.courseid);
      if (res.toDelete) await this.$router.push(`/admin/courses`);
    },
  },
  async mounted() {
    await this.getCourse()

    const res = await API.get(`user-access-types`)
    this.user_access_types = res.data.data
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";
</style>
